import React,{Component} from 'react';
import HomePageOne from './pages/Home';


class App extends Component{
  componentDidMount(){
    document.scrollingElement.scrollTop = 0;
  }
  render(){
    return (
      <div className="App">
          <HomePageOne/>
      </div>
    )
  }
}
export default App;
