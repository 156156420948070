import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import './apptidy.js';
import {BrowserRouter,Route,Switch} from 'react-router-dom';
import Navbar from './pages/components/Navbar';
import Lgpd from './pages/Lgpd';
import FooterArea from './pages/components/Footer';

ReactDOM.render(<BrowserRouter basename="/">
  <Navbar />
  <Switch>
    <Route  path="/" exact render={props => <App {...props}/>}/>
    <Route  path="/lgpd" render={props => <Lgpd {...props}/>}/>
    <Route path='/login' component={() => {
    window.location.href = 'http://adm.puzli.com.br';
    return null;
}}/>
    {/* <Route path="/" exact render={props => <HomePageOne {...props} />} />
    <Redirect to="/"/> */}
  </Switch>
  <FooterArea/>
</BrowserRouter>, document.getElementById('root'));
