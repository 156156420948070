import React from 'react';

const PDFViewer = () => {
  const pdfURL ='https://app.puzli.com.br/lgpd_jangadeiro.pdf';

  return (
    <div>
        <object data={pdfURL} type="application/pdf" width="100%" height="900px">
      <p><a href={pdfURL}>LGPD - JANGADEIRO </a></p>
  </object>
    </div>
  );
};

export default PDFViewer;
