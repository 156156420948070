import React,{Component} from "react";
import HeaderOne from './components/Header-1';
import HowItWorks from './components/HowItWorks';
// import Newslatter from './components/Newsletter';
import TeamMemberArea from './components/TeamMemberArea';
import ContactArea from './components/ContactArea';
import FooterArea from './components/Footer.js'
import Preloader from './components/Preloader';
import $ from 'jquery';

class HomePageOne extends Component{
  constructor(props) {
    super(props);
    this.state = {
      first: false,
    }
  }
  componentDidMount(){
   this.load()
  }

  load(){
    if(window.event == undefined){
    $(window).on('load',function(){
      var preLoder = $("#preloader");
      preLoder.fadeOut(1000);
  });}else{
  var preLoder = $("#preloader");
      preLoder.fadeOut(1000);
  }
  }
  

  // componentDidMount(){
  //   setthis.setState({first: true});
  // }

  render(){
    return (
      <main rel="main">
        {/* {this.state.first === false ?  */}
        <Preloader/>
        {/* :''} */}
        <HeaderOne/>
        {/* <AppFeatures/>
        <WhatWeOffer/> */}
        <HowItWorks/>
        {/* <ScreenshortArea/>
        <PricingArea/>
        <Newslatter/> */}
        <TeamMemberArea/>
        {/* <Testimonial/> */}
        {/* <VideoAndCounter/> */}
        {/* <DownloadArea/> */}
        <ContactArea/>
        {/* <BacktoTop/> */}
      </main>
    )
  }
}


export default HomePageOne;
