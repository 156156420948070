import React, { Component } from "react";
import { Link } from "react-router-dom";

class FooterArea extends Component {
  render() {
    return (
      <footer className="footer-area">
        <div className="footer-bottom">
          <div
            className="copyright-area"
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <div className="col1">
              <span>
                <Link to="/lgpd" className="privacidadeLink">Política de Privacidade</Link>
              </span>
            </div>
            <div className="left-content-area col2">
              &copy; 2022 Puzli. Todos os direitos reservados.
            </div>
            <div className="right-content-area col3">
              <ul className="social-icon">
                <li>
                  <a href="#!">
                    <i className="icofont-facebook"></i>
                  </a>
                </li>
                <li>
                  <a href="#!">
                    <i className="icofont-twitter"></i>
                  </a>
                </li>
                <li>
                  <a
                    href="https://instagram.com/puzli"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <i className="icofont-instagram"></i>
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </footer>
    );
  }
}

export default FooterArea;
