import React,{Component} from 'react';
import { Link } from 'react-router-dom';
import Swal from 'sweetalert2'

function gotowhatsapp() {
      
    var name = document.getElementById("name").value;
    var email = document.getElementById("email").value;
    var message = document.getElementById("message").value;

    if(!name | !email | !message){
        return (
        Swal.fire({
            icon: 'error',
            title: 'Campos obrigatórios.',
            text: 'Todos os campos são obrigatórios',
        }))
    }else{
  
    var url = `https://wa.me/5585981383196?text=Name:${name}%0a%0aTipo de evento:${email}%0a%0aMensagem:%0a${message}`; 
    window.open(url, '_blank').focus();

    }
  }

  const whats = () => {
    var url = 'https://wa.me/5585981383196';
    window.open(url, '_blank').focus();
  }

class ContactArea extends Component {
  state = {
    sectionTitle: {
      title: 'Fale Com a gente',
      subtitle: 'Entre em contato',
      paragraph: 'Ficou dúvida?'
    }
  }
  render(){
    return (
      <section className="contact-area" id="contact">
        <div className="container">
            <div className="row reorder-xs">
                <div className="col-lg-6">
                    <div className="left-content-area ">
                        <div className="section-inner-title">
                            <span className="subtitle">{this.state.sectionTitle.subtitle}</span>
                            <h3 className="title">{this.state.sectionTitle.title}</h3>
                            <p>{this.state.sectionTitle.paragraph}</p>
                        </div>
                        <div className="contact-form-wrapper justify-content-between">
                            <form className="contact-form" id="contact_form_submit">
                                <div className="form-group">
                                    <input type="text" id="name" className="form-control" placeholder="Name *" required/>
                                </div>
                                <div className="form-group">
                                    <input type="text" id="email" className="form-control" placeholder="Tipo de evento *" required/>
                                </div>
                                <div className="form-group textarea">
                                    <textarea id="message" cols="30" rows="5" className="form-control" placeholder="Message *" required></textarea>
                                </div>
                            <div className="btn-holder">
                                <Link to="/" target={"_blank"} rel="noreferrer"><button onClick={gotowhatsapp} className="submit-btn" >Enviar Mensagem</button></Link> 
                                <button className="submit-btn zap" onClick={whats}>Ir para WhatsApp</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
                <div className="col-lg-6">
                    <div className="right-content-aera">
                        <div className="img-wrapper">
                            <img src={require('../../assets/img/contact_right.jpg')} alt="contact right"/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    )
  }
}

export default ContactArea;
