import React,{Component} from 'react';
import SectionTitle from './SectionTitle';

class HowItWorks extends Component{
  state = {
    sectionTitle: {
      title: 'Como funciona',
      subtitle: '',
      paragraph: 'Nosso modo de trabalhar é fácil e simples, nossa equipe está sempre à disposição para ajudar e tirar dúvidas. E nosso aplicativo torna tudo mais rápido.',
      titleClass: 'p-width-lg'
    },
    howItWorksItems:[
      {
        theme:'bg1',
        icon: 'icofont-list',
        number: '01',
        title:'Formulário',
        paragraph: 'O convidado preenche um formulário com nome, foto e WhatsApp para receber o convite com seu QR Code individual e personalizado.'
      },
      {
        theme:'bg2',
        icon: 'icofont-checked',
        number: '02',
        title:'Aprovação',
        paragraph: 'Você, a Puzli ou seu(sua) cerimonialista aprovam os convidados. Os aprovados recebem o convite com seu QR Code no WhatsApp'
      },
      {
        theme:'bg3',
        icon: 'icofont-ticket',
        number: '03',
        title:'Aproveite',
        paragraph: 'No dia do evento, não tenha preocupação com pessoas indesejadas. O convite é individual e exclusivo. Ninguém entra sem ele.'
      },
    ]
  }
  render(){
    return (
      <section className="how-it-works">
          <div className="container">
              <div className="row justify-content-center">
                  <div className="col-lg-8">
                  <SectionTitle title={this.state.sectionTitle.title} subtitle={this.state.sectionTitle.subtitle} paragraph={this.state.sectionTitle.paragraph} titleClass={this.state.sectionTitle.titleClass}/>
                  </div>
              </div>
              <div className="row">
                  <div className="col-lg-12 text-center">
                      <div className="img-wrapper">
                          <img src={require('../../assets/img/we-offer-mobile.png')} alt="hot it works"/>
                      </div>
                  </div>
                  {
                    this.state.howItWorksItems.map((items,index) => (
                      <div className="col-lg-4 col-md-6" key={index}>
                          <div className="single-how-it-work ">
                              <div className={`icon ${items.theme}`}>
                                  <i className={items.icon}></i>
                                  <span className="num">{items.number}</span>
                              </div>
                              <div className="content">
                                  <h4 className="title">{items.title}</h4>
                                  <p>{items.paragraph}</p>
                              </div>
                          </div>
                      </div>
                    ))
                  }
              </div>
          </div>
      </section>
    )
  }
}

export default HowItWorks;
