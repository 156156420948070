import React, {Component} from 'react';

class SectionTitle extends Component{
  render(){
    return (
      <div className={`section-title ${this.props.titleClass}`} >
          <span className="subtitle">{this.props.subtitle}</span>
          <h2 className="title">{this.props.title}</h2>
          <p>{this.props.paragraph}</p>
      </div>
    )
  }
}

export default SectionTitle;
